@import '@/styles/variables.scss';




















































































































































































































































































































































































































































































































































































































.min-width-text {
  min-width: 160px;
}
.min-width-card {
  min-width: 500px;
}
